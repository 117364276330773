import './App.css';
import Navbar from './Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import Home from './Home'
import Footer from './Footer';
import { Switch, Route } from 'react-router-dom'

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Privacy_ru from './Privacy_ru';
import Privacy_en from './Privacy_en';
import Privacy_tk from './Privacy_tk';
import Privacy_tr from './Privacy_tr';
import i18next  from 'i18next';
import Application from './Application';


function App() {

  const { t, i18n } = useTranslation();

  const location = useLocation();
  return (
    <div className="App">
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/resume" component={Application} />
        <Route exact path='/privacy' component={i18n.language == 'ru' ? Privacy_ru : (i18n.language == 'en' ? Privacy_en : (i18n.language == 'tk' ? Privacy_tk : Privacy_tr))} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
