import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(Backend)
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: true, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          "our_address": "Our address:",
          "find_job": "Find a job today!",
          "yoda_ish": "yoda jobs",
          "address": "G. Kulyyev st. 29 (Rowana), 2nd floor, Ashgabat, Turkmenistan",
          "all_rights": "© 2023 Ýaşyl Ada. All rights reserved",
          "privacy": "Privacy Policy",
          "submit_app": "General Application",
          "name": "Full name",
          "gender": "Gender",
          "birthday": "Date of birth",
          "yashayan_yeri": "Location",
          "habarlashmak_ucin": "Contacts",
          "email": "E-mail",
          "hunar": "Job title",
          "tejribe": "Experience",
          "bilim": "Education",
          "okuw_jay": "Institution",
          "okuw_dowur": "Study period",
          "ukyplary": "Skills",
          "gosmaca": "Additional information",
          "resume": "Resume",
          "ugrat": "Submit",
          "hint_select_gender": "e.g. Male",
          "hint_select_city": "e.g. Ashgabat",
          "hint_enter_phone_number": "e.g. 64687171",
          "hint_enter_name": "e.g. Akmyrat Akmyradov",
          "hint_enter_email": "e.g. yashylada@gmail.com",
          "hint_select_hunar": "e.g. Moderator",
          "hint_select_experience": "e.g. Over 3 years",
          "hint_select_bilim": "e.g. Higher",
          "hint_select_university": "e.g. Oguz Han Engineering and Technology University of Turkmenistan",
          "hint_select_okuw_dowri_start_date": "Start date",
          "hint_select_okuw_dowri_end_date": "End date (or expected)",
          "hint_select_skills": "e.g. Microsoft Office, Turkmen language",
          "hint_enter_additional": "Tell us more about yourself and your professional achievements",
          "hint_select_file": "Choose file"

        }
      },
      tk: {
        translation: {
          "our_address": "Biziň salgymyz:",
          "address": "G. Kulyýew köç., 29 (Rowana), 2-nji gat, Aşgabat ş., Türkmenistan",
          "all_rights": "© 2023 Ýaşyl Ada. Ähli hukuklar goralan",
          "privacy": "Gizlinlik syýasaty",
          "submit_app": "Iş gözleýäniň anketasy",
          "name": "Doly ady",
          "gender": "Jynsy",
          "birthday": "Doglan güni",
          "yashayan_yeri": "Ýerleşýän ýeri",
          "habarlashmak_ucin": "Habarlaşmak üçin",
          "email": "E-poçta",
          "hunar": "Hünäri",
          "tejribe": "Tejribesi",
          "bilim": "Bilimi",
          "okuw_jay": "Okuw jaýy",
          "okuw_dowur": "Okuw döwri",
          "ukyplary": "Ukyplary",
          "gosmaca": "Goşmaça maglumatlar",
          "resume": "Rezýume",
          "ugrat": "Ugrat",
          "hint_select_gender": "meselem Erkek",
          "hint_select_city": "meselem Aşgabat",
          "hint_enter_phone_number": "meselem 64687171",
          "hint_enter_name": "meselem Akmyrat Akmyradow",
          "hint_enter_email": "meselem yashylada@gmail.com",
          "hint_select_hunar": "meselem Moderator",
          "hint_select_experience": "meselem 3 ýyldan köp",
          "hint_select_bilim": "meselem Ýokary",
          "hint_select_university": "meselem Türkmenistanyň Oguz han adyndaky Inžener-tehnologiýalar uniwersiteti",
          "hint_select_okuw_dowri_start_date": "Başlanýan senesi",
          "hint_select_okuw_dowri_end_date": "Tamamlanýan senesi (ýa-da garaşylýan)",
          "hint_select_skills": "meselem Microsoft Office, Türkmen dili",
          "hint_enter_additional": "Özüňiz we hünär üstünlikleriňiz barada has giňişleýin maglumat bilen paýlaşyň",
          "hint_select_file": "Faýl saýlaň"
        }
      },
      ru: {
        translation: {
          "our_address": "Наш адрес:",
          "yoda_ish": "yoda rabota",
          "find_job": "Find a job today!",
          "address": "ул. Г. Кулиева, 29 (Рована), 2-й этаж, г. Ашхабад, Туркменистан",
          "privacy": "Политика конфиденциальности",
          "all_rights": "© 2023 Ýaşyl Ada. Все права защищены",
          "privacy": "Политика конфиденциальности",
          "submit_app": "Анкета соискателя",
          "name": "Ф.И.О.",
          "gender": "Пол",
          "birthday": "Дата рождения",
          "yashayan_yeri": "Локация",
          "habarlashmak_ucin": "Контакты",
          "email": "Эл. почта",
          "hunar": "Должность",
          "tejribe": "Опыт",
          "bilim": "Образование",
          "okuw_jay": "Учреждение",
          "okuw_dowur": "Период обучения",
          "ukyplary": "Навыки",
          "gosmaca": "Дополнительная информация",
          "resume": "Резюме",
          "ugrat": "Отправить",
          "hint_select_gender": "напр. Мужской",
          "hint_select_city": "напр. Ашхабад",
          "hint_enter_phone_number": "напр. 64687171",
          "hint_enter_name": "напр. Акмырат Акмырадов",
          "hint_enter_email": "напр. yashylada@gmail.com",
          "hint_select_hunar": "напр. Модератор",
          "hint_select_experience": "напр. Больше 3 лет",
          "hint_select_bilim": "напр. Высшее",
          "hint_select_university": "напр. Университет инженерных технологий Туркменистана имени Огуз хана",
          "hint_select_okuw_dowri_start_date": "Дата начала",
          "hint_select_okuw_dowri_end_date": "Дата окончания (или ожидаемая)",
          "hint_select_skills": "напр. Microsoft Office, Туркменский язык",
          "hint_enter_additional": "Расскажите подробнее о себе и своих профессиональных достижениях",
          "hint_select_file": "Выберите файл"
        }
      },
      tr: {
        translation: {
          "our_address": "Bizim adresimiz:",
          "address": "G. Kulyyev Cad., 29 (Rowana), 2. kat, Aşkabat, Türkmenistan",
          "privacy": "Gizlinlik syýasaty",
          "all_rights": "© 2023 Ýaşyl Ada. Tüm Hakları Saklıdır",
          "privacy": "Gizlilik Politikası",
          "submit_app": "İş arayan başvuru formu",
          "name": "Ad Soyad",
          "gender": "Cinsiyet",
          "birthday": "Doğum tarihi",
          "yashayan_yeri": "Konum",
          "habarlashmak_ucin": "İletişim",
          "email": "E-posta",
          "hunar": "Pozisyon",
          "tejribe": "Deneyim süresi",
          "bilim": "Eğitim",
          "okuw_jay": "Kurum",
          "okuw_dowur": "Eğitim dönemi",
          "ukyplary": "Yetenekler",
          "gosmaca": "Ek bilgi",
          "resume": "CV",
          "ugrat": "Gönder",
          "hint_select_gender": "ör. Erkek",
          "hint_select_city": "ör. Aşkabat",
          "hint_enter_phone_number": "ör. 64687171",
          "hint_enter_name": "ör. Akmırat Akmıradov",
          "hint_enter_email": "ör. yashylada@gmail.com",
          "hint_select_hunar": "ör. Moderatör",
          "hint_select_experience": "ör. 3 yıldan fazla",
          "hint_select_bilim": "ör. Yüksek",
          "hint_select_university": "ör. Oğuzhan Teknoloji Mühendisliği Üniversitesi",
          "hint_select_okuw_dowri_start_date": "Başlangıç ​​tarihi",
          "hint_select_okuw_dowri_end_date": "Bitiş tarihi (veya beklenen)",
          "hint_select_skills": "ör. Microsoft Office, Türkmen dili",
          "hint_enter_additional": "Bize kendiniz ve profesyonel başarılarınız hakkında daha fazla bilgi veriniz",
          "hint_select_file": "Dosya seçin"
        }
      }
    }
  });

export default i18n;