import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom'

const Footer = () => {

    const { t, i18n } = useTranslation();
    return (
        <footer className='container-fluid px-0 mx-0'>
            <div className='container-fluid align-items-center footer-text' style={{ background: '#0f9898'}}>
                <div className='container py-3'>
                    <p className='text-white' style={{ 'marginLeft': '6px' }}>{t('our_address')}</p>

                    <div className='d-flex align-items-center mb-2'>
                        <div className='align-items-center ml-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none" style={{ marginLeft: '6px' }}><path d="M2.98039 2.29289C4.0466 1.22667 5.49216 0.626861 7 0.625C8.50784 0.626861 9.9534 1.22667 11.0196 2.29289C12.086 3.35923 12.6858 4.80502 12.6875 6.31307C12.6873 8.78571 11.3694 10.996 9.96476 12.6422C8.59559 14.2468 7.19703 15.2532 7.04304 15.3625C7.03007 15.3703 7.01519 15.3744 7 15.3744C6.98481 15.3744 6.96993 15.3702 6.95695 15.3625C6.80297 15.2532 5.40441 14.2468 4.03524 12.6422C2.63055 10.9959 1.3126 8.78554 1.3125 6.31278C1.31429 4.80484 1.9141 3.35917 2.98039 2.29289ZM8.52782 4.02596C8.07558 3.72378 7.5439 3.5625 7 3.5625C6.27065 3.5625 5.57118 3.85223 5.05546 4.36796C4.53973 4.88368 4.25 5.58315 4.25 6.3125C4.25 6.8564 4.41128 7.38808 4.71346 7.84032C5.01563 8.29255 5.44512 8.64503 5.94762 8.85317C6.45012 9.06131 7.00305 9.11577 7.5365 9.00966C8.06995 8.90355 8.55995 8.64164 8.94454 8.25704C9.32914 7.87245 9.59105 7.38245 9.69716 6.849C9.80327 6.31555 9.74881 5.76262 9.54067 5.26012C9.33253 4.75762 8.98005 4.32813 8.52782 4.02596Z" fill="white" stroke="white"></path></svg>

                        </div>
                        <div className='text-white mx-2'>
                            {t('address')}
                        </div>
                    </div>

                    <div className='d-flex align-items-center mb-2'>
                        <div className='align-items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" style={{ marginLeft: '6px' }}>
                                <path d="M15.6094 11.1374L12.3117 9.7311C12.1416 9.65484 11.9548 9.62321 11.769 9.63921C11.5832 9.6552 11.4046 9.71829 11.25 9.82251L9.48516 10.9967C8.40955 10.4743 7.53868 9.6084 7.01016 8.53579L8.17734 6.74985C8.28087 6.59508 8.3441 6.4169 8.36129 6.23149C8.37848 6.04607 8.34909 5.8593 8.27578 5.68813L6.8625 2.39048C6.76388 2.16781 6.5969 1.98234 6.38577 1.86097C6.17463 1.7396 5.93033 1.68864 5.68828 1.71548C4.73878 1.83986 3.86675 2.30482 3.23432 3.02389C2.6019 3.74297 2.25211 4.66724 2.25 5.62485C2.25 11.2077 6.79219 15.7499 12.375 15.7499C13.3326 15.7477 14.2569 15.398 14.976 14.7655C15.695 14.1331 16.16 13.2611 16.2844 12.3116C16.3112 12.0695 16.2603 11.8252 16.1389 11.6141C16.0175 11.403 15.832 11.236 15.6094 11.1374Z" fill="white" />
                            </svg>
                        </div>
                        <div>
                            <a className='text-decoration-none text-white mx-2' href="tel:+993 64 687171">+993 64 687171</a>
                        </div>
                    </div>

                    <div className='d-flex align-items-center mb-2'>
                        <div className='align-items-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" style={{ marginLeft: '6px' }}>
                                <path d="M16.0101 3.39598L12.3258 16.4671C12.2643 16.6884 12.1362 16.8854 11.9588 17.0314C11.7814 17.1773 11.5634 17.2651 11.3344 17.2827H11.2359C11.024 17.2822 10.8166 17.2219 10.6375 17.1087C10.4583 16.9955 10.3148 16.834 10.2234 16.6429L7.71327 11.3413C7.68863 11.2881 7.68081 11.2286 7.69086 11.1708C7.70091 11.113 7.72835 11.0596 7.76952 11.0179L11.3766 7.41083C11.433 7.35966 11.4785 7.29756 11.5102 7.22828C11.542 7.15901 11.5593 7.08401 11.5612 7.00783C11.5631 6.93165 11.5494 6.85589 11.5212 6.78514C11.4929 6.71439 11.4505 6.65012 11.3966 6.59624C11.3427 6.54236 11.2785 6.49999 11.2077 6.47169C11.137 6.4434 11.0612 6.42978 10.985 6.43166C10.9088 6.43353 10.8338 6.45087 10.7646 6.4826C10.6953 6.51434 10.6332 6.55983 10.582 6.61629L6.97499 10.2304C6.93321 10.2715 6.87986 10.299 6.82206 10.309C6.76427 10.3191 6.70479 10.3113 6.65156 10.2866L1.37812 7.79051C1.17172 7.69303 0.998437 7.53716 0.879713 7.3422C0.760989 7.14725 0.702036 6.92176 0.710149 6.69364C0.722595 6.45876 0.808801 6.2338 0.9565 6.05075C1.1042 5.86771 1.30587 5.73591 1.53281 5.67411L14.632 1.9827C14.8611 1.91727 15.1051 1.92627 15.3288 2.00841C15.5524 2.09055 15.7442 2.24158 15.8766 2.43973C15.9653 2.58055 16.0232 2.73863 16.0462 2.90349C16.0692 3.06835 16.0569 3.23623 16.0101 3.39598Z" fill="white" />
                            </svg>
                        </div>
                        <div>
                            <a className="text-decoration-none text-white mx-2" href="mailto:yashylada@gmail.com">yashylada@gmail.com</a>
                        </div>
                    </div>

                    <div className='align-items-center mx-2 d-block d-md-flex d-lg-flex text-white'>
                        <div className='mb-2 mb-lg-0 mb-md-0'>{t('all_rights')}</div> <div className='mx-2 d-sm-none d-none d-md-block'>|</div>
                        <NavLink className="text-decoration-none text-white" to="/privacy">{t('privacy')}</NavLink>
                    </div>

                </div>

            </div>
        </footer>

    )
};

export default withTranslation()(Footer);

