import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const Privacy = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className='container position-relative mb-5'>

            <div className='d-flex mb-5'>
                <NavLink to="/" className="d-flex text-decoration-none">

                    <div style={{ marginRight: '10px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M16.875 14.6248H15.75V8.12439C15.75 7.96869 15.7177 7.81468 15.6552 7.67211C15.5926 7.52953 15.5011 7.4015 15.3865 7.29611L9.76148 1.98892C9.75873 1.98652 9.75615 1.98394 9.75375 1.98119C9.54665 1.79284 9.27677 1.68848 8.99684 1.68848C8.7169 1.68848 8.44702 1.79284 8.23992 1.98119L8.23219 1.98892L2.61352 7.29611C2.4989 7.4015 2.40742 7.52953 2.34485 7.67211C2.28227 7.81468 2.24998 7.96869 2.25 8.12439V14.6248H1.125C0.975816 14.6248 0.832742 14.684 0.727252 14.7895C0.621763 14.895 0.5625 15.0381 0.5625 15.1873C0.5625 15.3365 0.621763 15.4795 0.727252 15.585C0.832742 15.6905 0.975816 15.7498 1.125 15.7498H16.875C17.0242 15.7498 17.1673 15.6905 17.2727 15.585C17.3782 15.4795 17.4375 15.3365 17.4375 15.1873C17.4375 15.0381 17.3782 14.895 17.2727 14.7895C17.1673 14.684 17.0242 14.6248 16.875 14.6248ZM10.6875 14.6248H7.3125V11.2498C7.3125 11.1006 7.37176 10.9575 7.47725 10.852C7.58274 10.7465 7.72582 10.6873 7.875 10.6873H10.125C10.2742 10.6873 10.4173 10.7465 10.5227 10.852C10.6282 10.9575 10.6875 11.1006 10.6875 11.2498V14.6248Z" fill="#0F9898" />
                        </svg>
                    </div>

                    <span className='ml-3' style={{ color: 'rgb(15, 152, 152)' }}>
                        Baş sahypa
                    </span>

                </NavLink>
                <div className='mx-2'> > </div>
                <span className="text-decoration-none text-dark">{t('privacy')}</span>
            </div>
            <header>
                <h3>{t('privacy')}</h3>
            </header>

            <main>
                <section>
                   <p>
                   Siziň şahsy maglumatlaryňyzyň gizlinligini we olaryň goraglylygyny üpjün etmäge borçlanýarys. Maglumatlaryňyzy diňe platformamyzda ulanýan hyzmatlaryňyzy üpjün etmek we Ýoda Iş platformamyzy kämilleşdirmek üçin ýygnaýarys we ulanýarys. Maglumatlaryňyzy marketing maksatly üçünji taraplara hiç bir ýagdaýda geçirilmez we paýlaşylmaz.

                   </p>
                </section>
                <section>
                    <h6 className='fw-bold' >Aşakdaky maglumatlary ýygnaýarys:</h6 >
                    <ul>
                        <li>Adyňyz we habarlaşmak üçin maglumatlaryňyz.</li>
                        <li>Rezýume we iş bilen baglanyşykly beýleki maglumatlar.</li>
                        <li>Biziň platformamyzdaky ulanyjylyk we gözleg işjeňligiňiz.</li>
                    </ul>
                </section>


                <section>
                    <h6 className='fw-bold'>Maglumatlaryňyzy aşakdaky maksatlar üçin ulanýarys:</h6>
                    <ul>
                        <li>Platformamyzda hödürlenýän hyzmatlary bermek üçin, meselem, iş gözlemek;
                        </li>
                        <li>Platformamyzy we hyzmatlarymyzy kämilleşdirmek üçin;
                        </li>
                        <li>Platformamyz we hyzmatlarymyz hakda marketing habarnamalaryny ibermek üçin;
                        </li>
                        <li>
                            Degişli kanunlary we düzgünleri ýerine ýetirmek üçin.

                        </li>
                    </ul>
                </section>
                <section>
                    <h6 className='fw-bold'>Maglumatlaryňyz babatynda aşakdaky hukuklaryňyz bar:</h6>

                    <ul>
                        <li>Siz hakda bar bolan maglumatlaryň göçürmesini talap etmäge.
                        </li>
                        <li>Siz hakda bar bolan nädogry ýa-da doly däl maglumatlary düzetmegimizi talap etmäge.</li>

                        <li>Maglumatlaryňyzy platformadan ýok etmegimizi talap etmäge.
                        </li>
                    </ul>
                </section>

                <section>

                    <p>Bu hukuklary ulanmak üçin ýa-da şu Gizlinlik syýasaty barada soraglaryňyz bolsa, <a href="mailto:yashylada@gmail.com"> yashylada@gmail.com</a> adresi boýunça bize ýüz tutup bilersiňiz.
                    </p>

                </section>

                <section className='mt-5'>
                    <p>
                    Ýaşyl Ada, Platformany işläp taýýarlan
                    </p>
                </section>
            </main>
        </div>
    )
}

export default Privacy