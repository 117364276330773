import React from 'react';
import screen from './images/screen.png'
import app_icon from './images/app_icon.png'
import './App.css';
import googlePlay from './images/google-play.png'
import appStore from './images/app-store.png';
import { NavLink } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import Footer from './Footer';

function Home() {

    const { t, i18n } = useTranslation();
    return (

            <div className="container d-flex justify-content-between align-items-center home">
                <div className='row'>

                    <div className=" my-auto">
                        <div className='center  flex-column align-items-center text-center'>
                            <img src={app_icon} alt="" height={150} className='mb-5' />
                        </div>
                    </div>

                    <div className='col-md-12 my-auto text-center '>
                        <img src={screen} alt="" className='img-responsive phones w-75' />
                    </div>

                    <div className="buttons d-flex my-5 justify-content-center" >
                        <a href='#' className=' mx-3 pointer-events-none'>
                            <img src={googlePlay} alt="" />
                        </a>

                        <a className='mx-3' href='#'>
                            <img src={appStore} alt="" />
                        </a>


                    </div>

                </div>
            </div>
    )
}

export default withTranslation()(Home);