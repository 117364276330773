import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom'
import { useTranslation, withTranslation } from 'react-i18next';
import axios from "axios";
import InfiniteScroll from 'react-infinite-scroll-component';

import "react-datepicker/dist/react-datepicker.css";

import { Modal, Button } from 'react-bootstrap'


const Application = () => {

    const [show, setShow] = useState(false);

    const [successModalShow, setSuccessModalShow] = useState(false);

    const [ukypModalshow, setUkypModalShow] = useState(false);
    const [skills, setSkills] = React.useState([]);

    const [skillsSelected, setSkillsSelected] = React.useState([]);

    const [date, setDate] = useState(new Date());

    const [startDate, setStartDate] = useState(new Date());

    const [endDate, setEndDate] = useState(new Date());

    const [additional, setAdditional] = useState('');

    const [birthday, setBirthday] = useState('');

    const [phoneNumber, setPhoneNumber] = useState('');

    const [email, setEmail] = useState('');

    const [universities, setUniversities] = React.useState([]);

    const [genders, setGenders] = React.useState([]);

    const [cities, setCities] = React.useState([]);

    const [degrees, setDegrees] = React.useState([]);

    const [degree, setDegree] = React.useState('');

    const [university, setUniversity] = React.useState(null);

    const [gender, setGender] = React.useState('');

    const [city, setCity] = React.useState('');

    const [experience, setExperience] = React.useState('');

    const [Hunar, setHunar] = React.useState('');

    const [careerOptions, setCareerOptions] = useState([]);

    const [experiences, setExperiences] = useState([]);

    const [fullName, setFullName] = useState([]);

    const [careerCount, setCareerCount] = useState(0);

    const [page, setPage] = useState(1);

    const [hasNextPage, setHasNextPage] = useState(false);

    const [loading, setLoading] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);

    const [search, setSearch] = useState('');

    const [errors, setErrors] = useState({});

    const emailRef = useRef(null);

    const degreeRef = useRef(null);

    const hunarRef = useRef(null);

    const experienceRef = useRef(null);

    const initModal = () => {
        setShow(true)
    }

    const Loader = () => {
        return <div class="h-100 w-100 spinner-grow text-info" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    }

    const resetFields = () => {
        setEmail(null)
        setAdditional(null)
        setCity(null)
        setDegree(null)
        setExperience(null)
        setGender(null)
        setPhoneNumber(null)
        setSelectedFile(null)
        setHunar(null)
        setFullName(null)
        setBirthday(null)
    }

    const SubmitForm = () => {

        const err = {};
        if (!fullName) {
            err.fullName = 'Name is required';
        }

        if (!Hunar) {
            err.hunar = 'Hunar field is required';
            hunarRef.current.scrollIntoView({ behavior: 'smooth' });
        }

        if (!degree) {
            err.degree = 'degree field is required';
            degreeRef.current.scrollIntoView({ behavior: 'smooth' });
        }

        if (!experience) {
            err.experience = 'experience field is required';
            experienceRef.current.scrollIntoView({ behavior: 'smooth' });
        }

        if (!email || !email.trim()) {
            emailRef.current.scrollIntoView({ behavior: 'smooth' });

            err.email = 'Email is required';

        } else if (!/\S+@\S+\.\S+/.test(email)) {
            err.email = 'Invalid email format';
            emailRef.current.scrollIntoView({ behavior: 'smooth' });

        }
        setErrors(err)

        console.log(Object.keys(errors).length, 'ERROR LENGTH');

        console.log(Object.keys(err).length, 'ERR LENGTH');

        if (Object.keys(err).length > 0) return;

        console.log('selectedFile', selectedFile)


        const formData = new FormData();
        setLoading(true)

        if (email !== null) {
            formData.append('email', email);
        }

        if (selectedFile !== null) {
            formData.append('attachment', selectedFile);
        }

        if (gender !== null) {
            formData.append('gender', gender);
        }

        if (birthday !== null) {
            formData.append('birthday', birthday);
        }


        if (fullName !== null) {
            formData.append('full_name', fullName);
        }

        if (phoneNumber !== null) {
            formData.append('phones', phoneNumber);
        }

        if (experience !== null) {
            formData.append('experience', experience);
        }

        if (startDate !== null) {
            formData.append('startDate', startDate);
        }

        if (endDate !== null) {
            formData.append('endDate', endDate);
        }


        if (degree !== null) {
            formData.append('degree', degree);
        }

        if (university !== null) {
            formData.append('university', university);
        }

        if (Hunar !== null) {
            formData.append('career', Hunar?.id);
        }


        if (city !== null) {
            formData.append('city', city);
        }


        if (additional !== null) {
            formData.append('description', additional);
        }

        if (skillsSelected.length > 0) {
            skillsSelected.map(skil => {
                return formData.append('skill', skil.id);
            })
        }



        axios.post(baseURL + 'resume/', formData, {
        }).then((response) => {
            setLoading(false)

            resetFields()

            setSuccessModalShow(true)
        }).catch((error) => {
            setLoading(false)
            console.error(error);
        });

    }

    useEffect(() => {
        axios.get(baseURL + 'university', {
            withCredentials: false,
        }).then((response) => {
            setUniversities(response.data?.results);
        });

        axios.get(baseURL + 'gender', {
            withCredentials: false,
        }).then((response) => {
            setGenders(response.data?.results);
        });

        axios.get(baseURL + 'degree', {
            withCredentials: false,
        }).then((response) => {
            setDegrees(response.data?.results);
        });

        axios.get(baseURL + 'city', {
            withCredentials: false,
        }).then((response) => {
            setCities(response.data?.results);
        });

        axios.get(baseURL + 'experience', {
            withCredentials: false,
        }).then((response) => {
            setExperiences(response.data?.results);
        });

        getPaginatedData(1);
        getUkypPaginatedData(1);
    }, [])
    const getPaginatedData = (page, search) => {

        axios.get(baseURL + `career/`, {
            params: {
                page,
                search
            },
            withCredentials: false,
        }).then((response) => {
            setCareerCount(response.data?.count)

            setHasNextPage(response.data?.next !== null)
            // if (search !== null) {
            //     setCareerOptions(response.data?.results);
            // } else {
            setCareerOptions([...careerOptions, ...response.data?.results]);
            // }
            setPage(page + 1)
        });

    }

    const getPaginatedDataSearch = (search) => {
        axios.get(baseURL + `career/?page=1`, {
            params: {
                search
            },
            withCredentials: false,
        }).then((response) => {
            console.log('RESPONSE WHEN SEARCH', response.data)
            setCareerCount(response.data?.count)
            setHasNextPage(response.data?.next !== null)
            setCareerOptions(response.data?.results);
        });

    }


    const getUkypPaginatedDataSearch = (search) => {
        axios.get(baseURL + `skill/?page=1`, {
            params: {
                search
            },
            withCredentials: false,
        }).then((response) => {
            console.log('RESPONSE WHEN SEARCH SKILL', response.data)
            setHasNextPage(response.data?.next !== null)
            setSkills(response.data?.results);
        });

    }

    const getUkypPaginatedData = (page, search) => {

        axios.get(baseURL + `skill/`, {
            params: {
                page,
                search
            },
            withCredentials: false,
        }).then((response) => {
            setCareerCount(response.data?.count)
            setHasNextPage(response.data?.next !== null)
            setSkills([...skills, ...response.data?.results]);
            setPage(page + 1)
        });

    }

    const { t, i18n } = useTranslation();

    const baseURL = i18n.language == 'tk' ? `https://yodajobs.app/api/v1/` : `https://yodajobs.app/${i18n.language.slice(0,2)}/api/v1/`;

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    return (
        <div className='container position-relative mb-5'>
            <div className='d-flex mb-5'>
                <NavLink to="/" className="d-flex text-decoration-none">
                    <div style={{ marginRight: '10px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M16.875 14.6248H15.75V8.12439C15.75 7.96869 15.7177 7.81468 15.6552 7.67211C15.5926 7.52953 15.5011 7.4015 15.3865 7.29611L9.76148 1.98892C9.75873 1.98652 9.75615 1.98394 9.75375 1.98119C9.54665 1.79284 9.27677 1.68848 8.99684 1.68848C8.7169 1.68848 8.44702 1.79284 8.23992 1.98119L8.23219 1.98892L2.61352 7.29611C2.4989 7.4015 2.40742 7.52953 2.34485 7.67211C2.28227 7.81468 2.24998 7.96869 2.25 8.12439V14.6248H1.125C0.975816 14.6248 0.832742 14.684 0.727252 14.7895C0.621763 14.895 0.5625 15.0381 0.5625 15.1873C0.5625 15.3365 0.621763 15.4795 0.727252 15.585C0.832742 15.6905 0.975816 15.7498 1.125 15.7498H16.875C17.0242 15.7498 17.1673 15.6905 17.2727 15.585C17.3782 15.4795 17.4375 15.3365 17.4375 15.1873C17.4375 15.0381 17.3782 14.895 17.2727 14.7895C17.1673 14.684 17.0242 14.6248 16.875 14.6248ZM10.6875 14.6248H7.3125V11.2498C7.3125 11.1006 7.37176 10.9575 7.47725 10.852C7.58274 10.7465 7.72582 10.6873 7.875 10.6873H10.125C10.2742 10.6873 10.4173 10.7465 10.5227 10.852C10.6282 10.9575 10.6875 11.1006 10.6875 11.2498V14.6248Z" fill="#0F9898" />
                        </svg>
                    </div>
                    <span className='ml-3' style={{ color: 'rgb(15, 152, 152)' }}>
                        Home
                    </span>

                </NavLink>
                <div className='mx-2'> > </div>
                <span className="text-decoration-none text-dark">{t('submit_app')}</span>
            </div>

            <Modal show={successModalShow} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header className='flex' closeButton onClick={() => {
                    setSuccessModalShow(false)
                    window.location.reload()
                }}>

                </Modal.Header>
                <Modal.Body>

                    <div className='d-flex flex-column justify-content-center'>
                        <h4 className='d-flex justify-content-center fw-bold my-5'>Application submitted successfully</h4>

                        <div className='d-flex justify-content-center'>
                            <button className='btn bg-dark-primary text-white m-3' onClick={() => {
                                setSuccessModalShow(false)
                                window.location.reload();
                                // resetFields()
                            }}>Submit New</button>

                            <NavLink to="/" className='btn btn-outline m-3'>Go Home page</NavLink>
                        </div>


                    </div>


                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
            <main className='container mx-0 px-0'>
                <div className='row  mb-3 mb-lg-5'>
                    <div className='form-group col-md-8'>
                        <label className='fw-bold' htmlFor="Ady">{t('name')}</label>
                        <input type="text" name="" className='form-control input_background' onChange={(e) => {
                            setFullName(e.target.value)
                        }} placeholder={t('hint_enter_name')} />

                    </div>
                </div>

                <div className='row mb-3 mb-lg-5 g-3 g-lg-4'>
                    <div className='form-group col-md-4'>
                        <label className='fw-bold' htmlFor="Ady">{t('gender')}</label>

                        <select className={!gender ? 'form-control input_background unselected' : 'form-control input_background'} onChange={(e) => {
                            setGender(e.target.value)
                        }}>
                            <option value="" selected disabled>{t('hint_select_gender')}</option>
                            {genders.map(gender => {
                                return <option value={gender.id} key={gender.id}>{gender.name}</option>
                            })}
                        </select>
                    </div>

                    <div className='form-group col-md-4'>
                        <label className='fw-bold' htmlFor="Ady">{t('birthday')}</label>
                        <input type="date" className='form-control input_background' onChange={(e) => {
                            setBirthday(e.target.value)
                        }} placeholder='enter name...' />

                    </div>

                    <div className='form-group col-md-4'>
                        <label className='fw-bold' htmlFor="yashayan_yeri">{t('yashayan_yeri')}</label>
                        <select onChange={(e) => {
                            setCity(e.target.value)

                        }} id="city" className={!city ? 'form-control input_background unselected' : 'form-control input_background'}>
                            <option value="" selected disabled>Select city...</option>
                            {cities.map(city => {
                                return <option value={city.id} key={city.id}>{city.name}</option>
                            })}
                        </select>
                    </div>
                </div>

                <div className='row mb-3 mb-lg-5 g-3 g-lg-4'>
                    <div className='form-group col-md-4'>
                        <label className='fw-bold' htmlFor="Ady">{t('habarlashmak_ucin')}</label>

                        <div className='d-flex'>
                            <span style={{ background: '#F2F4F5', padding: '10px', borderTopLeftRadius: '10px', border: '', borderBottomLeftRadius: '10px' }}>+993</span>
                            <input type="text" className='form-control' style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0', borderTopRightRadius: '10px', borderBottomRightRadius: '10px', background: '#F2F4F5' }} onChange={(e) => {
                                setPhoneNumber(e.target.value)
                            }} placeholder={t('hint_enter_phone_number')} />
                        </div>
                    </div>

                    <div className='form-group col-md-8' ref={emailRef}>
                        <label className='fw-bold' htmlFor="Ady">E-mail {errors.email && <span className='text-danger'>*</span>}</label>
                        <input type="text" id='emailid' value={email} className={errors.email ? 'form-control input_background border-danger' : 'form-control input_background'} onChange={(e) => {
                            setEmail(e.target.value)
                        }} placeholder={t('hint_enter_email')} />
                    </div>
                </div>

                <div className='row mb-3 mb-lg-5 g-3 g-lg-4'>
                    <div className='form-group col-md-8' ref={hunarRef}>
                        <label className='fw-bold' htmlFor="Hunar">{t('hunar')} {errors.hunar && <span className='text-danger'>*</span>}</label>

                        <input type="text" name="" className={errors.hunar ? 'form-control input_background border-danger' : 'form-control input_background'} value={Hunar?.name} onClick={() => {
                            setShow(true)
                            setSearch('');
                            getPaginatedData(1)
                        }} placeholder={t('hint_select_hunar')} />
                        {/* Hunar Modal */}
                        <Modal show={show} centered aria-labelledby="contained-modal-title-vcenter">
                            <Modal.Header className='flex'>
                                <input type="text" onChange={(e) => {
                                    setCareerOptions([]);
                                    setSearch(e.target.value)
                                    getPaginatedDataSearch(search)
                                }} className='form-control pr-2' />
                                <Button className="close bg-white text-dark border-primary" onClick={() => {
                                    setShow(false)
                                }}>
                                    <span aria-hidden="true">&times;</span>
                                </Button>
                            </Modal.Header>
                            <Modal.Body id='scrollableDiv' style={{ height: "200px", overflowY: "scroll" }}>

                                <InfiniteScroll
                                    dataLength={careerOptions.length} //This is important field to render the next data
                                    next={() => {
                                        getPaginatedData(page, search)

                                    }}
                                    scrollableTarget="scrollableDiv"
                                    hasMore={hasNextPage}
                                    loader={
                                        <div className='d-flex justify-content-center'>
                                            <div className="spinner-grow text-info" role="status">
                                            </div>
                                        </div>
                                    }
                                    endMessage={() => {
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }

                                    }
                                    // below props only if you need pull down functionality
                                    refreshFunction={() => {

                                    }}
                                    pullDownToRefresh
                                    pullDownToRefreshThreshold={50}
                                    pullDownToRefreshContent={
                                        <h3 className='text-center'>&#8595; Pull down to refresh</h3>
                                    }
                                    releaseToRefreshContent={
                                        <h3 className='text-center'>&#8593; Release to refresh</h3>
                                    }
                                >
                                    <ul className="list-group">
                                        {careerOptions.map(career => {
                                            const classNames = career.id == Hunar?.id ? 'list-group-item cusor-pointer bg-dark-primary text-white' : 'list-group-item cusor-pointer'
                                            return <li className={classNames} onClick={(item) => {
                                                setHunar(career)
                                                setShow(false)
                                                setCareerOptions([])
                                            }} key={career.id}>
                                                {career.name}
                                            </li>
                                        })}

                                    </ul>
                                </InfiniteScroll>

                            </Modal.Body>
                            <Modal.Footer>

                            </Modal.Footer>
                        </Modal>
                    </div>

                    <div className='form-group col-md-4' ref={experienceRef}>
                        <label className='fw-bold' htmlFor="Hunar">{t('tejribe')} {errors.experience && <span className='text-danger'>*</span>}</label>
                        <select name="" className={errors.experience ? 'form-control input_background border-danger unselected' : !experience ? 'form-control input_background unselected' : 'form-control input_background'} onChange={(e) => {
                            setExperience(e.target.value)
                        }}>
                            <option value="" selected disabled>{t('hint_select_experience')}</option>
                            {experiences.map(experience => {
                                return <option value={experience.id} key={experience.id}>{experience.name}</option>
                            })}
                        </select>
                    </div>
                </div>

                <div className='row mb-3 mb-lg-5 g-3 g-lg-4'>
                    <div className='form-group col-md-4' ref={degreeRef}>
                        <label className='fw-bold' htmlFor="bilim">{t('bilim')} {errors.degree && <span className='text-danger'>*</span>}</label>

                        <select className={errors.degree ? 'form-control input_background border-danger' : !degree ? 'form-control input_background unselected' : 'form-control input_background'} onChange={(e) => {
                            setDegree(e.target.value)
                        }}>
                            <option value="" selected disabled>{t('hint_select_bilim')}</option>
                            {degrees.map(degree => {
                                return <option value={degree.id} key={degree.id}>{degree.name}</option>
                            })}
                        </select>
                    </div>

                    <div className='form-group col-md-8'>
                        <label className='fw-bold' htmlFor="Ady">{t('okuw_jay')}</label>
                        <select name="" onChange={(e) => {
                            setUniversity(e.target.value)
                        }} id="university" className={!university ? 'form-control input_background unselected' : 'form-control input_background'}>
                            <option value="" selected disabled>{t('hint_select_university')}</option>
                            {universities.map(univ => {
                                return <option value={univ.id} key={univ.id}>{univ.name}</option>
                            })}
                        </select>
                    </div>
                </div>

                <div className='row mb-3 mb-lg-5 g-3 g-lg-4'>
                    <div className='form-group col-md-5 sm-col-12'>
                        <label className='fw-bold' htmlFor="Ady">{t('okuw_dowur')}</label>
                        <div className='form-control justify-content-between input_background d-flex'>
                            <span className='pl-4'>{t('hint_select_okuw_dowri_start_date')}</span>
                            <input type="date" className='baslanyan_sene' onChange={(e) => {
                                setStartDate(e.target.value)
                            }} placeholder='enter name...' />
                        </div>
                    </div>

                    <div className='form-group col-md-5 sm-col-12'>
                        <label className='fw-bold' htmlFor="Ady"> </label>
                        <div className='form-control justify-content-between input_background d-flex'>
                            <span className='pl-4'>{t('hint_select_okuw_dowri_end_date')}</span>
                            <input type="date" className='baslanyan_sene' onChange={(e) => {
                                setEndDate(e.target.value)
                            }} placeholder='enter name...' />
                        </div>
                    </div>
                </div>

                <div className='row mb-3 mb-lg-5 g-3 g-lg-4'>
                    <div className='form-group col-md-12'>
                        <label className='fw-bold' htmlFor="Ady">{t('ukyplary')}</label>
                        <input type="text" name="" className='form-control input_background' value={skillsSelected.map(skill => {
                            return ' ' + skill.name
                        })} onClick={() => {
                            setUkypModalShow(true)
                            getUkypPaginatedData(1, '')

                        }} placeholder={t('hint_select_skills')} />

                        <Modal show={ukypModalshow} centered aria-labelledby="contained-modal-title-vcenter">
                            <Modal.Header className='flex'>
                                <input type="text" onChange={(e) => {
                                    setSearch(e.target.value)
                                    getUkypPaginatedDataSearch(search)

                                    console.log('show skillsSelected when modal pops up', skillsSelected)
                                }} className='form-control' />
                                <Button className="close bg-white text-dark border-primary" onClick={() => {
                                    setUkypModalShow(false)
                                }}><span aria-hidden="true">&times;</span></Button>
                            </Modal.Header>
                            <Modal.Body id='scrollableDivUkyp' style={{ height: "200px", overflowY: "scroll" }}>

                                <InfiniteScroll
                                    dataLength={skills.length} //This is important field to render the next data
                                    next={() => {
                                        getUkypPaginatedData(page, search)

                                    }}
                                    scrollableTarget="scrollableDivUkyp"
                                    hasMore={hasNextPage}
                                    loader={
                                        <div className='d-flex justify-content-center'>
                                            <div className="spinner-grow text-info" role="status">
                                            </div>
                                        </div>
                                    }
                                    endMessage={() => {
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }

                                    }
                                    // below props only if you need pull down functionality
                                    refreshFunction={() => {

                                    }}
                                    pullDownToRefresh
                                    pullDownToRefreshThreshold={50}
                                    pullDownToRefreshContent={
                                        <h3 className='text-center'>&#8595; Pull down to refresh</h3>
                                    }
                                    releaseToRefreshContent={
                                        <h3 className='text-center'>&#8593; Release to refresh</h3>
                                    }
                                >
                                    <ul className="list-group">
                                        {skills.map((skill) => {
                                            const classNames = skillsSelected.includes(skill) ? 'list-group-item cusor-pointer bg-dark-primary text-white' : 'list-group-item cusor-pointer'
                                            return <li className={classNames} onClick={(e) => {
                                                e.preventDefault();

                                                console.log('console log BEFORE FILTER', skillsSelected);

                                                console.log('skill onclick', skill);

                                                if(skillsSelected.includes(skill)){
                                                    console.log('INCLUDES')
                                                    skillsSelected.filter(function(skil){
                                                       return skill.id !== skil.id 
                                                    });
                                                    setSkillsSelected(skillsSelected)
                                                } else {
                                                    console.log('NOT INCLUDING')
                                                    setSkillsSelected(skillsSelected=>[...skillsSelected, skill]);
                                                }

                                                console.log('skillsSelected', skillsSelected)

                                            }} key={skill.id}>
                                                {skill?.name}
                                            </li>
                                        })}

                                    </ul>
                                </InfiniteScroll>

                            </Modal.Body>

                            <Modal.Footer>
                                <Button className='btn btn-light' onClick={() => {
                                    setUkypModalShow(false)
                                    setSkills([])
                                    setSearch('')

                                }}>
                                    Close
                                </Button>
                                <Button className='bg-dark-primary' onClick={() => {
                                    setUkypModalShow(false)
                                    setSkills([])
                                    setSearch('')
                                }}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </div>
                </div>

                <div className='row mb-3 mb-lg-5 g-3 g-lg-4'>
                    <div className='form-group col-md-12'>
                        <label className='fw-bold' htmlFor="Ady">{t('gosmaca')}</label>
                        <textarea rows="10" className='form-control input_background' placeholder={t('hint_enter_additional')} onChange={(e) => {
                            setAdditional(e.target.value)
                        }}></textarea>

                    </div>
                </div>

                <div className='row mb-3 mb-lg-5 g-3 g-lg-4'>
                    <div className='form-group col-md-4'>
                        <label className='fw-bold' htmlFor="Ady">{t('resume')}</label>
                        <input type="file" className='form-control input_background' onChange={handleFileChange} />
                    </div>
                </div>


                <div className='row mb-3 mb-lg-5 g-3 g-lg-4'>
                    <div className='form-group col-md-4'>
                        <button className='btn btn d-flex text-white px-5 primaryButton text-decoration-none' onClick={(e) => {
                            e.preventDefault()
                            SubmitForm()
                        }}>
                            <span className='px-2'>{t('ugrat')}</span>
                            <div className='ml-5'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                    <path d="M18.5781 3.46407C18.5781 3.46407 18.5781 3.47189 18.5781 3.47579L14.0312 18.4711C13.9624 18.7147 13.8207 18.9313 13.6252 19.0921C13.4297 19.2528 13.1897 19.3499 12.9374 19.3703C12.9015 19.3734 12.8656 19.375 12.8296 19.375C12.5932 19.3757 12.3615 19.3088 12.1619 19.1822C11.9623 19.0555 11.8031 18.8744 11.7031 18.6602L8.91322 12.7703C8.88567 12.7121 8.87682 12.6467 8.88787 12.5832C8.89892 12.5197 8.92933 12.4612 8.97493 12.4156L13.4484 7.94142C13.5606 7.82325 13.6223 7.66591 13.6202 7.50293C13.6181 7.33996 13.5525 7.18424 13.4372 7.06899C13.322 6.95374 13.1662 6.88807 13.0033 6.88598C12.8403 6.8839 12.6829 6.94556 12.5648 7.05782L8.08431 11.5313C8.03877 11.5769 7.98024 11.6073 7.91675 11.6183C7.85325 11.6294 7.78789 11.6205 7.72962 11.593L1.87025 8.8172C1.64012 8.71177 1.44646 8.54042 1.31379 8.32486C1.18111 8.10929 1.11539 7.8592 1.12493 7.60626C1.13766 7.34629 1.23178 7.09689 1.39398 6.89333C1.55619 6.68977 1.77827 6.54234 2.02884 6.47188L17.0242 1.92501H17.0359C17.2494 1.86503 17.475 1.86292 17.6896 1.91892C17.9042 1.97491 18.1 2.08698 18.257 2.24364C18.4139 2.40029 18.5264 2.59589 18.5828 2.81036C18.6393 3.02483 18.6376 3.25045 18.5781 3.46407Z" fill="white" />
                                </svg>
                            </div>
                        </button>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Application