import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import App from './App';
import './i18n';


// i18n.use(initReactI18next).use(LanguageDetector).use(HttpApi).init({
//   supportedLngs: ['tk', 'ru','en','tr'],

//   detection: {
//     order: ['localStorage', 'htmlTag', 'cookie', 'path', 'subdomain', 'sessionStorage'],
//     caches: ['localStorage'],
//   },
//   backend: {
//     loadPath: './locales/{{lng}}/translation.json',
//   },
//   react: { useSuspense: false },
//   fallbackLng: "ru",
//   debug: true,

// });


const loadingMarkUp = (
  <div className='py-4 text-center'>
    <h2>
      Loading...
    </h2>

  </div>
)




ReactDOM.render(

  <Suspense fallback={loadingMarkUp}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
