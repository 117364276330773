import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import image from './images/navbar.png';
import './navbar.css';
import i18next from 'i18next';
import { useTranslation, withTranslation } from 'react-i18next';

const languages = [
    {
        code: 'ru',
        name: 'РУС',
        country_code: 'ru'
    },
    {
        code: 'tk',
        name: 'TKM',
        country_code: 'tk'
    },
    {
        code: 'tr',
        name: 'TUR',
        country_code: 'tr'
    },
    {
        code: 'en',
        name: 'ENG',
        country_code: 'en'
    }
];

function Navbar() {
    const { t, i18n } = useTranslation();

    return (
        <div className='container'>
            <nav className="navbar navbar-expand-lg navbar-light container my-3 my-lg-5 my-md-3 my-sm-0">

                <div className="navbar-nav ms-auto my-auto">

                    <NavLink to="/resume" className="btn text-white primaryButton text-decoration-none">
                    {t('submit_app')}
                    </NavLink>
                    <div className='lang-bar d-lg-flex'>

                        {languages.map(({ code, name, country_code }) => (
                            <NavLink className={`nav-item nav-link ${i18n.language.slice(0,2) === code ? 'active' : ''}`} to={`/${code}`} key={code} onClick={(e) => {
                                e.preventDefault();
                                i18next.changeLanguage(code)
                                window.location.reload()
                            }}>{name}</NavLink>
                        ))}
                    </div>
                </div>
                {/* </div> */}

            </nav>
        </div>
    )
}

export default withTranslation()(Navbar);
